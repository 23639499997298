@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body{
  position: relative;
  background-color: rgb(51, 51, 51);;
}

