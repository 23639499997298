@font-face {
	font-family: 'Muli Light';
	src:url('../public/fonts/muli.light.ttf')format("truetype")
}

@font-face {
	font-family: 'Muli Regular';
	src:url('../public/fonts/muli.regular.ttf')format("truetype")
}

@font-face {
	font-family: 'Muli SemiBold';
	src:url('../public/fonts/Muli-SemiBold.ttf')format("truetype")
}
