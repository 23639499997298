.header{
    font-family: 'Quicksand', sans-serif;
    display: flex;
    justify-content: center;
    padding: 16px 24px;
    background-color: rgb(51, 51, 51);;
}
.header span{
    font-size: 22px;
    font-weight: 400;
    color: rgb(169, 169, 169);
    letter-spacing: 0.091em;
    text-align: center;
}
.banner{
    width: 100%;
    background-color: white;
}
.banner img{
    width: 100%;
    height: auto;
}

.legalExpert{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 56px 0px;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.legalExpert h1{
    font-family: "Muli Regular";
    font-size: 42px !important;
    color: rgb(22, 22, 22);
    letter-spacing: 0.023em;
    font-weight: 400;
    text-align: center;
}
.legalExpert button{
    padding: 8px 16px;
    min-height: 56px;
    font-size: 14px;
    font-family: 'Quicksand', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid black;
}
.legalExpert button a{
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
}
.legalExpert button:hover{
    background-color: black;
    color: white;
}
.legalExpert button:hover a{
    color: white;
}
.legalExpert button span{
    margin: 0px 8px;
    font-weight: 700;
}
.legalExpert .btnStyle{
    width: 18px;
    height: 0.1px;
    background-color: black;
}
.legalExpert button:hover .btnStyle{
    background-color: white;
}


.footer{
    padding: 56px 40px;
    background-color: rgb(51, 51, 51);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    color: rgb(169, 169, 169);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.167em;
    line-height: 1.5;
}

.messageDiv{
    position: fixed;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: rgb(226, 226, 226);
    box-shadow: rgb(0 0 0 / 25%) 0px 3px 18px;
    bottom: 30px;
    right: 30px !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.messageDiv a{
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.messageForm{
    width: 382px;
    position: absolute;
    height: calc(100vh - 120px);
    bottom: 120%;
    background-color: white;
    right: 0px;
    border-radius: 5px;
}


.messageHeader{
    padding: 16px 24px 40px 24px;
    background-color: rgb(226, 226, 226);
    font-family:'Quicksand', sans-serif;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
}

@media (max-width: 920px){
    .legalExpert{
        padding: 40px 24px;
    }
    .footer{
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .copyrightPara{
        margin-top: 24px;
    }
}

@media (max-width: 767px){
    .banner{
       display: flex;
    }
    .banner img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .legalExpert{
        padding: 40px 24px;
        background-color: white;
    }
    .legalExpert h1{
        font-size: 32px !important;
    }
    .legalExpert button{
        width: 100%;
    }
    .messageDiv{
        position: fixed;
    }
    .footer{
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .copyrightPara{
        margin-top: 24px;
    }
}